import { AT, CH, CZ, DE, LU, NL, SE, SK } from "country-flag-icons/react/3x2"; // eslint-disable-line node/file-extension-in-import
// types
import type { ReactNode } from "react";
import type { Country } from "../../types/misc";

/* eslint-disable @stylistic/jsx-pascal-case */
const FLAGS = {
	AT: <AT />,
	CH: <CH />,
	CZ: <CZ />,
	DE: <DE />,
	LU: <LU />,
	NL: <NL />,
	SE: <SE />,
	SK: <SK />,
} as const satisfies Partial<Record<Country, ReactNode>>;
/* eslint-enable @stylistic/jsx-pascal-case */

export default FLAGS;
