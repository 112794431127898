import { Capacitor } from "@capacitor/core";
import Analytics from "../../services/analytics";
import Constants from "../../services/constants";
import WebtrekkActions from "./webtrekk-actions";
// types
import type { RouteChanges, ActionList, EventList, HbDataLayerData } from "../../types/analytics";
import type { Platform } from "../../types/misc";

const routeChanges = [
	{
		location: `^/history/(${Object.values(Constants.Tabs.History).join("|")})`, // TODO: end with `$`?
		action: WebtrekkActions.acHistory,
	},
	{
		location: "^/account$",
		action: WebtrekkActions.acAccount,
	},
	{
		location: "^/help", // TODO: end with `$`?
		action: WebtrekkActions.acSupport,
	},
	{
		location: "^/about$",
		action: WebtrekkActions.acAbout,
	},
	{
		location: "^/about/userterms$",
		action: WebtrekkActions.acAboutUserTerms,
	},
	{
		location: "^/about/imprint$",
		action: WebtrekkActions.acAboutImprint,
	},
	{
		location: "^/add/home", // TODO: end with `$`?
		action: WebtrekkActions.acAddGateway,
	},
	{
		location: `^/add/device/pairing/(${Object.values(Constants.PairingCategoryType).join("|")})`, // TODO: end with `$`?
		action: WebtrekkActions.acAddDevice,
	},
	{
		location: "^/add/group$",
		action: WebtrekkActions.acAddGroup,
	},
	{
		location: "^/add/rule", // TODO: end with `$`?
		action: WebtrekkActions.acAddRule,
	},
	{
		location: "^/explore/[A-Z]{2}$",
		action: WebtrekkActions.acExplorePage,
	},
] as const satisfies RouteChanges;

const actionList = [
	{
		/** @deprecated Old quickview doesn't exist any more */
		name: "openQuickView",
		action: (data) => (WebtrekkActions.acGatewaySituation(data.smartMode, data.deviceGroupsCount, data.deviceGroupNames, data.devicesPerGroup)),
	},
	{
		name: "openDevices",
		action: (data) => (WebtrekkActions.acDevices(data.deviceCategoriesCount, data.deviceCategoryNames, data.devicesPerCategory)),
	},
	{
		name: "openDeviceDetails",
		action: (data) => (WebtrekkActions.acDeviceDetails(data.deviceName)),
	},
	{
		name: "openDeviceDiagram",
		action: (data) => (WebtrekkActions.acDeviceMeasuredValues(data.clusterId, data.deviceName)),
	},
	{
		name: "openRules",
		action: (data) => (WebtrekkActions.acRules(data.rulesCount)),
	},
	{
		name: "openRuleDetailsTemplate",
		action: (data) => (WebtrekkActions.acRuleDetailsTemplate(data.ruleName)),
	},
	{
		name: "openDetailsTimeSwitchDiagram",
		action: (data) => (WebtrekkActions.acRuleDetailsTimeSwitchingDiagram(data.deviceName, data.ruleName)),
	},
	{
		name: "errorMessage",
		action: (data) => (WebtrekkActions.acError(data.errorMessage)),
	},
] as const satisfies ActionList;

const eventList = [
	{
		name: "smartModeChange",
		event: (data) => (WebtrekkActions.evGatewaySituation(data.smartMode)),
	},
	{ // TODO: unused
		name: "deviceUpdate",
		event: (data) => (WebtrekkActions.evDeviceUpdate(data.deviceName)),
	},
	{
		name: "ruleTabChange",
		event: (data) => (WebtrekkActions.evRulesTab(data.ruleTab)),
	},
	{
		name: "eventSmartModesChange",
		event: (data) => (WebtrekkActions.evGatewaySituationChange(data.smartModes)),
	},
	{
		name: "historyTabChange",
		event: (data) => (WebtrekkActions.evHistoryTab(data.historyTab)),
	},
	{
		name: "historyFilterChange",
		event: (data) => (WebtrekkActions.evHistoryFilter(data.historyFilterCount)),
	},
	{
		name: "deleteAccount",
		event: () => (WebtrekkActions.evAccountDelete()),
	},
	{
		name: "zWaveModeChange",
		event: (data) => (WebtrekkActions.evZWaveMode(data.zwaveMode)),
	},
	{
		name: "addGateway",
		event: () => (WebtrekkActions.evAddGateway()),
	},
	{
		name: "addDevice",
		event: (data) => (WebtrekkActions.evAddDevice(data.deviceCategoryName, data.deviceName)),
	},
	{
		name: "addGroup",
		event: (data) => (WebtrekkActions.evAddGroup(data.devicesCount)),
	},
	{
		name: "addRule",
		event: (data) => (WebtrekkActions.evAddRule(data.smartModes, data.ruleName)),
	},
	{
		name: "exploreCarouselView",
		event: (data) => (WebtrekkActions.evExploreCarouselView(data.text, data.position)),
	},
	{
		name: "exploreCarouselClick",
		event: (data) => (WebtrekkActions.evExploreCarouselClick(data.text, data.position)),
	},
	{
		name: "exploreBrandsInteraction",
		event: (data) => {
			WebtrekkActions.evExploreClick(data.text); // added this, to make the brands also visible in ther "Partner section" of our webtrekk explore report
			WebtrekkActions.evExploreBrandsInteraction(data.text, data.interactionType, data.position);
		},
	},
	{
		name: "exploreClick",
		event: (data) => (WebtrekkActions.evExploreClick(data.text)),
	},
	{
		name: "exploreChangeCountry",
		event: (data) => (WebtrekkActions.evExploreChangeCountry(data.countryName)),
	},
] as const satisfies EventList;

class AnalyticsHornbach extends Analytics {

	constructor() {
		super();

		if (window.hbDataLayer) {
			this.routeChanges = routeChanges;
			this.actionList = actionList;
			this.eventList = eventList;
			const platform = Capacitor.getPlatform() as Platform;
			window.hbDataLayer.app_platform = (platform === "web") ? platform : `${platform} app`;
		}
	}

	public override emitFirstLoad(hbDataLayerData: HbDataLayerData): void {
		if (window.hbDataLayer) {
			WebtrekkActions.updateHbDataLayer(hbDataLayerData);
		}
	}

}

export default (new AnalyticsHornbach());
